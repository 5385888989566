import config from '../../constants/config';
import CookiesButton from 'src/components/Buttons/CookiesButton';
import LanguageButton from '../../components/Buttons/LanguageButton';
import PropTypes from 'prop-types';
import React from 'react';
import SVG from '../../components/Images/SvgRenderer';
import { createUseStyles } from 'react-jss';
import { getUserHomepage } from 'src/utils/useUser';
import { isCypress } from 'src/utils/useCypress';
import { getQueryParams, getQueryString, isValidHexColor, parseEnviromentName } from 'src/utils/useFunctions';
import { setChangelogModal } from 'src/store/actions/modals.actions';
import { Tooltip } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import { useLocation, useNavigate } from 'react-router';
import { useTranslation } from 'react-i18next';
import theme from 'src/ui/theme';

interface Props {
  children: any;
};

interface ClassesProps {
  backgroundColor?: any;
  isEmbed: any;
};

const useStyles = createUseStyles((theme: any) => ({
  authLayout: {
    width: '100vw',
    height: '100vh',
    overflow: 'auto',
    backgroundColor: (props: ClassesProps) => props.backgroundColor,
    position: 'relative',
  },
  authToolbar: {
    paddingRight: '16px',
    paddingTop: '16px',
    display: 'flex',
    justifyContent: 'flex-end',
    textAlign: 'right',
    gap: '4px',
  },
  authLogo: {
    textAlign: 'center',
    cursor: 'pointer',
    marginTop: (props: ClassesProps) => {
      if(props.isEmbed) return '16px';
      else return '';
    },
    '& > svg': {
      maxWidth: '90%',
      height: '64px',
    },
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    gap: '0.1rem',
    width: '100%',
    marginBottom: '16px',
    maxWidth: 'calc(100% - 2rem)',
  },
  footerRow: {
    display: 'flex',
    justifyContent: 'center',
    gap: '0.5rem',
    width: '100%',
    maxWidth: 'calc(100% - 2rem)',
    '& > span': {
      color: theme.colors.primaryBlue[500],
      fontSize: '12px',
      whiteSpace: 'nowrap',
      '& > span': {
        fontWeight: 'bold',
      },
      '&:last-of-type': {
        cursor: 'pointer',
        transition: 'all 0.25s',
        '&:hover': {
          textDecoration: 'underline',
        },
      },
    },
  },
}));

const AuthLayout: React.FunctionComponent<Props> = (props: Props) => {

  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate = useNavigate();
  const dataData = useAppSelector((state: any) => state.data);
  const layoutData = useAppSelector((state: any) => state.layout);
  const userData = useAppSelector((state: any) => state.user);
  const { t } = useTranslation();

  const queryParams = getQueryParams(location);
  const queryString = getQueryString(location);
  const embed = queryParams.get("embed");
  const isEmbed = embed === "true" ? true : false;
  const background = queryParams.get("background");
  const backgroundColor = isEmbed ? (background === "gradient" ? theme.colors.gradient : (isValidHexColor(`#${background}`) ? `#${background}` : theme.colors.white)) : theme.colors.white;
  const isBranch = config.ENVIRONMENT === "review";
  const branch = config.BRANCH;

  const classes = useStyles({
    backgroundColor: backgroundColor,
    isEmbed: isEmbed,
  }); 

  const handleLogoClick = () => {
    navigate(userData.userStatus === "loggedIn" ? `${getUserHomepage(dispatch, dataData, layoutData, userData)}${queryString}` : `/${queryString}`);
  };

  const handleOnClickVersion = () => {
    const settings = {
      isOpen: true,
      isAutoOpen: false,
    };
    dispatch(setChangelogModal(settings));
  };
  
  return (
    <div className={classes.authLayout}>
      {
        !isEmbed ? (
          <div className={classes.authToolbar}>
            <CookiesButton/>
            <LanguageButton/>
          </div>
        ) : null
      }
      <div className={classes.authLogo} onClick={handleLogoClick} data-cy={isCypress() ? `authLayoutLogo` : null}>
        <SVG src={config.APP_LOGO}/>
      </div>
      {props.children}
      <div className={classes.footer} data-cy={isCypress() ? `authLayoutFooter` : null}>
        {
          isBranch ? (
            <div className={classes.footerRow}>
              <span data-cy={isCypress() ? `authLayoutFooterBranch` : null}>
                {t('branch')} <span>{parseEnviromentName(branch)}</span>
              </span>
            </div>
          ) : null
        }
        {
          !isEmbed ? (
            <div className={classes.footerRow}>
              <Tooltip title={t('twigsee_since')} arrow>
                <span data-clarity-unmask="true" data-cy={isCypress() ? `authLayoutFooterCopyright` : null}>
                  &copy; {t('twigsee')} {new Date().getFullYear()}
                </span>
              </Tooltip>
              <span data-cy={isCypress() ? `authLayoutFooterDivider` : null}>|</span>
              <span data-clarity-unmask="true" data-cy={isCypress() ? `authLayoutFooterVersion` : null} onClick={handleOnClickVersion}>
                {t('version')} {config.APP_VERSION}
              </span>
            </div>
          ) : null
        }
      </div>
    </div>
  );
}

AuthLayout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object,PropTypes.func]).isRequired
};

export default AuthLayout;