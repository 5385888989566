import * as AuthService from '../../../services/auth.service';
import AuthenticatedImage from 'src/components/Items/AuthenticatedImage';
import config from 'src/constants/config';
import Label from 'src/components/Forms/Label';
import NormalButton from 'src/components/Buttons/NormalButton';
import React, { useCallback, useRef } from 'react';
import { CircularProgress } from '@mui/material';
import { createNotification } from 'src/utils/createNotification';
import { createUseStyles } from 'react-jss';
import { getAppAccessToken, getAppApiServer, getAppData, setAppAccessToken, setAppData } from 'src/utils/useApp';
import { getQueryParams, getQueryString, isExistInArrayWithObject, isKey, trimCharacter } from 'src/utils/useFunctions';
import { resetAttachments, resetImages, resetVideos } from 'src/store/actions/medias.actions';
import { setInitializeUserController, setReinitializeUserController } from 'src/store/actions/controllers.actions';
import { setUserObject, setUserStatus } from 'src/store/actions/user.actions';
import { useAppDispatch, useAppSelector } from 'src/hooks/redux-hooks';
import { useEffect } from 'src/utils/useEffect';
import { useLocation, useNavigate, useParams } from 'react-router';
import { useStates } from 'src/utils/useState';
import { useTranslation } from 'react-i18next';

interface Props {
  isEmbed: any,
};

const useStyles = createUseStyles((theme: any) => ({
  ssoLoginPage: {
    marginTop: '55px',
    marginBottom: '55px',
    height: 'calc(100% - 272px)',
  },
  loginWrapper: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
    gap: '20px',
    [theme.breakpoints.down('md')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  loginCard: {
    maxWidth: 'calc(400px - 96px)',
    width: '100%',
    padding: '24px 48px 32px 48px',
    boxShadow: (props: Props) => {
      if(props.isEmbed) return '';
      else return '0 4px 12px rgba(0, 0, 0, 0.1)';
    },
    borderRadius: '12px',
    height: 'fit-content',
    [theme.breakpoints.down('md')]: {
      maxWidth: 'calc(100% - 96px)',
      marginBottom: '24px',
      padding: '16px 24px 24px 24px',
      '& + div': {
        marginLeft: '0px',
      },
    },
  },
  loginCardTitle: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    marginTop: '16px',
    fontWeight: 'bold',
    fontSize: '14px',
    color: theme.colors.black,
  },
  loginCardAvatarWrapper: {
    textAlign: 'center',
    marginTop: '16px',
  },
  loginCardAvatar: {
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '80px',
    height: '80px',
    backgroundColor: theme.colors.grey[250],
    borderRadius: '100%',
    overflow: 'hidden',
    '& + div': {
      marginLeft: '10px',
    },
    '& div': {
      position: 'relative',
      width: '80px',
      height: '80px',
    },
  },
  loginCardForm: {
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start', 
  },
  skeletonLabel: {
    marginTop: '16px',
    marginBottom: '7px',
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    marginTop: '16px',
  },
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    '& > button': {
      width: '100%',
      fontSize: '14px',
      '& svg': {
        width: '20px',
        height: '20px',
        marginRight: '5px',
      },
    },
  },
  customLink: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginTop: '24px',
    '& > span': {
      color: theme.colors.primaryBlue[500],
      fontWeight: 'bold',
      textDecoration: 'underline',
      fontSize: '14px',
      cursor: 'pointer',
    },
  },
  loading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    height: '100%',
  },
  spinner: {
    '& svg': {
      color: theme.colors.primaryBlue[500]
    }
  },
  loadingInfo: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginTop: '16px',
  },
}));

const SSOToken: React.FunctionComponent = () => {  

  const dispatch = useAppDispatch();
  const location = useLocation();
  const { t } = useTranslation();
  const { token }: any = useParams();
  const userData = useAppSelector((state: any) => state.user);
  const savedData = getAppData();
  const navigate = useNavigate();
  const ssoToken: string = token;
  const useApiServers = config.APP_ENVIRONMENT !== "production" && Object.keys(config.API_SERVERS).length;

  const queryParams = getQueryParams(location);
  const queryString = getQueryString(location);
  const target = queryParams.get("target");
  const embed = queryParams.get("embed");
  const isEmbed = embed === "true" ? true : false;
  const forceLogin = queryParams.get("forceLogin");
  const isForceLogin = forceLogin === "true" ? true : false;

  const classes = useStyles({
    isEmbed: isEmbed,
  });

  const [state, setState] = useStates({
    isLoaded: false,
    isAutoLoading: false,
    isLoading: false,
    isFailed: false,
    accessToken: null,
    userData: null,
  });

  const tempUserData: any = useRef({
    accessToken: null,
    user: {},
  });

  const handleContinueAsCurrentUser = (e: any) => {
    e.preventDefault();
    dispatch(resetAttachments());
    dispatch(resetImages());
    dispatch(resetVideos());
    setAppData({user: userData.userObject});
    tempUserData.current = {
      accessToken: getAppAccessToken(),
      user: userData.userObject,
    };
    dispatch(setReinitializeUserController(true));
  };

  const handleAutoLogin = useCallback((accessToken: any, newUserData: any) => {
    setState("isLoading", true);
    setAppAccessToken(accessToken);
    const savedUsers = savedData.users ? savedData.users : [];
    const userDataToStorage = {
      userID: newUserData.userID,
      email: newUserData.email,
      firstname: newUserData.firstname,
      surname: newUserData.surname,
      roleType: newUserData.roleType,
      photo: newUserData.photo.thumbLink,
    };
    if(savedUsers.length > 0 && !isExistInArrayWithObject(savedUsers, "email", newUserData.email)) {
      const userDataToSave: any = {
        userID: newUserData.userID,
        email: newUserData.email,
        firstname: newUserData.firstname,
        surname: newUserData.surname,
        roleType: newUserData.roleType,
        photo: newUserData.photo.thumbLink,
        accessToken: accessToken,
      };
      if(useApiServers) {
        userDataToSave.apiServer = getAppApiServer();
      }
      const newSavedUsers = [...savedUsers, userDataToSave];
      setAppData({user: userDataToStorage, users: newSavedUsers});
    } else {
      const newUsersData = savedUsers.map((item: any) => {
        if(item.userID === newUserData.userID) {
          return {...item, accessToken: accessToken};
        } else {
          return item;
        }
      });
      setAppData({user: userDataToStorage, users: newUsersData});
    }
    dispatch(resetAttachments());
    dispatch(resetImages());
    dispatch(resetVideos());
    tempUserData.current = {
      accessToken: accessToken,
      user: newUserData,
    };
    dispatch(setReinitializeUserController(true));
  }, [dispatch, savedData.users, setState, useApiServers]);

  const handleContinueAsNewUser = (e?: any) => {
    e.preventDefault();
    setState("isLoading", true);
    const savedUsers = savedData.users ? savedData.users : [];
    const userDataToStorage = {
      userID: state.userData.userID,
      email: state.userData.email,
      firstname: state.userData.firstname,
      surname: state.userData.surname,
      roleType: state.userData.roleType,
      photo: state.userData.photo.thumbLink,
    };
    if(savedUsers.length > 0 && !isExistInArrayWithObject(savedUsers, useApiServers ? ["email", "apiServer"] : "email", useApiServers ? [state.userData.email, getAppApiServer()] : state.userData.email)) {
      const userDataToSave: any = {
        userID: state.userData.userID,
        email: state.userData.email,
        firstname: state.userData.firstname,
        surname: state.userData.surname,
        roleType: state.userData.roleType,
        photo: state.userData.photo.thumbLink,
        accessToken: state.accessToken,
      };
      if(useApiServers) {
        userDataToSave.apiServer = getAppApiServer();
      }
      const newSavedUsers = [...savedUsers, userDataToSave];
      setAppData({user: userDataToStorage, users: newSavedUsers});
    } else {
      const newUsersData = savedUsers.map((item: any) => {
        if(item.userID === state.userData.userID) {
          return {...item, accessToken: state.accessToken};
        } else {
          return item;
        }
      });
      setAppData({user: userDataToStorage, users: newUsersData});
    }
    dispatch(resetAttachments());
    dispatch(resetImages());
    dispatch(resetVideos());
    tempUserData.current = {
      accessToken: state.accessToken,
      user: state.userData,
    };
    dispatch(setUserStatus("reInitialization"));
  };

  useEffect(() => {
    if(userData.userStatus === "reInitialization" && Object.keys(tempUserData.current.user).length !== 0) {
      const redirect = decodeURIComponent(queryString).replace(`?target=${target}&`, '?').replace(`?target=${target}`, '').replace(`&target=${target}`, '').replace(`?forceLogin=${forceLogin}&`, '?').replace(`?forceLogin=${forceLogin}`, '').replace(`&forceLogin=${forceLogin}`, '');
      navigate(target ? `/${trimCharacter(target, "/")}${redirect}` : `/${redirect}`);
      setAppAccessToken(tempUserData.current.accessToken);
      dispatch(setUserObject(tempUserData.current.user));
      dispatch(setUserStatus("loggedIn"));
      dispatch(setInitializeUserController(true));
    }
  }, [dispatch, navigate, userData.userStatus, state.accessToken, tempUserData.current.user, target, forceLogin, queryString], [userData.userStatus, tempUserData.current.user]);

  const handleLogin = () => {
    navigate(`/auth/login${queryString}`);
  };

  useEffect(() => {
    if(ssoToken) {
      AuthService && AuthService.ssoSignIn(ssoToken).then((result: any) => {
        if(result.data.authToken && result.data.user) {
          const accessToken = result.data.authToken;
          const newUserData = result.data.user;
          setState("accessToken", accessToken);
          setState("userData", newUserData);
          setState("isLoaded", true);
          if(userData.userStatus !== "loggedIn" || (userData.userStatus === "loggedIn" && userData.userObject.userID === newUserData.userID) || isForceLogin) {
            setState("isAutoLoading", true);
            handleAutoLogin(accessToken, newUserData);
          }
        }
      }).catch((e: any) => {
        const errorMessage = (e.response && e.response.data && !isKey(e.response.data.message)) ? e.response.data.message : t("sso_failed_login");
        if(userData.userStatus === "loggedIn") {
          createNotification(errorMessage, "error");
          setState("isLoaded", true);
        }
        setState("isFailed", errorMessage);
      });
    } else {
      if(userData.userStatus === "loggedIn") {
        createNotification(t("sso_no_token"), "error");
        setState("isLoaded", true);
      }
      setState("isFailed", t("sso_failed_login"));
    }
  }, [dispatch, navigate, t, ssoToken, userData.userStatus, userData.userObject.userID, setState, handleAutoLogin, isForceLogin], []);

  return (
    <div className={classes.ssoLoginPage}>
      {
        state.isLoaded ? (
          <div className={classes.loginWrapper}>
            {
              ((!state.isFailed && !state.isLoaded) || (state.isLoaded && userData.userObject && state.userData && userData.userObject.userID !== state.userData.userID)) ? (
                <>
                  {
                    state.isLoaded ? (
                      <div className={classes.loginCard}>
                        <div className={classes.loginCardTitle}>
                          <span>{t('sso_continue_as')}</span>
                          <span>{t('sso_new_user').toLowerCase()}</span>
                        </div>
                        <div className={classes.loginCardAvatarWrapper}>
                          <div className={classes.loginCardAvatar}>
                            <AuthenticatedImage thumbLink={state.userData.photo.thumbLink} customAccessToken={userData.userStatus !== "loggedIn" ? state.accessToken : null}/>
                          </div>
                        </div>
                        <form className={classes.loginCardForm} onSubmit={handleContinueAsNewUser}>
                          <Label>{t('user')}:</Label>
                          <span>{state.userData.firstname} {state.userData.surname}</span>
                          <Label>{t('email')}:</Label>
                          <span>{state.userData.email}</span>
                          <div className={classes.footer}>
                            {
                              state.isAutoLoading ? (
                                <div className={classes.loading}>
                                  <CircularProgress className={classes.spinner}/>
                                </div>
                              ) : (
                                <div className={classes.button}>
                                  <NormalButton tabIndex="2" type="submit" buttonType="primary" dataCy="loginButtonAsNewUser" disabled={state.isLoading}>
                                    {t("sso_login_continue")}
                                  </NormalButton>
                                </div>
                              )
                            }
                          </div>
                        </form>
                      </div>
                    ) : null
                  }
                </>
              ) : (
                <>
                {
                  userData.userStatus !== "loggedIn" ? (
                    <div className={classes.loginCard}>
                      <div className={classes.loginCardTitle}>
                        <span>{state.isFailed}</span>
                      </div>
                      <div className={classes.customLink}>
                        <span onClick={handleLogin}>{t('return_login')}</span>
                      </div>
                    </div>
                  ) : null
                }
                </>
              )
            }
            {
              userData.userStatus === "loggedIn" ? (
                <div className={classes.loginCard}>
                  <div className={classes.loginCardTitle}>
                    <span>{t('sso_continue_as')}</span>
                    <span>{t('sso_current_user').toLowerCase()}</span>
                  </div>
                  <div className={classes.loginCardAvatarWrapper}>
                    <div className={classes.loginCardAvatar}>
                      <AuthenticatedImage thumbLink={typeof userData.userObject.photo === "string" ? userData.userObject.photo : userData.userObject.photo.thumbLink}/>
                    </div>
                  </div>
                  <form className={classes.loginCardForm} onSubmit={handleContinueAsCurrentUser}>
                    <Label>{t('user')}:</Label>
                    <span>{userData.userObject.firstname} {userData.userObject.surname}</span>
                    <Label>{t('email')}:</Label>
                    <span>{userData.userObject.email}</span>
                    <div className={classes.footer}>
                      <div className={classes.button}>
                        <NormalButton tabIndex="1" type="submit" buttonType={(state.isFailed || (state.isLoaded && userData.userObject.userID === state.userData.userID)) ? "primary" : "secondary"} dataCy="loginButtonAsCurrentUser" disabled={!state.isLoaded}>
                          {t("sso_login_continue")}
                        </NormalButton>
                      </div>
                    </div>
                  </form>
                </div>
              ) : null
            }
          </div>
        ) : (
          <div className={classes.loginWrapper}>
            <div className={classes.loginCard}>
              <div className={classes.loginCardTitle}>
                <span>{t('sso_processing_token')}...</span>
              </div>
              <div className={classes.loadingInfo}>
                <CircularProgress className={classes.spinner}/>
              </div>
            </div>
          </div>
        )
      }
    </div>
  );
}

export default SSOToken;