import Calendar from '../../../components/Calendars/Calendar';
import CalendarMenu from 'src/components/Menus//ToolbarMenu/Calendar';
import React from 'react';
import SubscriptionBanner from 'src/components/Layouts/SubscriptionBanner';
import { createUseStyles } from 'react-jss';
import { getQueryParams } from 'src/utils/useFunctions';
import { Navigate, useLocation } from 'react-router';
import { useAppSelector } from 'src/hooks/redux-hooks';

const useStyles = createUseStyles((theme: any) => ({
  calendarPage: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: '100%',
    overflow: 'auto',
    padding: '0px',
    flex: '0 0 auto',
    alignItems: 'center',
    position: 'relative',
    [theme.breakpoints.down('md')]: {
      width: '100%',
      maxWidth: '100%',
      padding: '0px',
    },
  },
  calendarMenu: {
    position: 'sticky',
    top: '0',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    width: 'calc(100% - 32px)',
    gap: '8px',
    padding: '8px 16px',
    zIndex: theme.zIndex.topBar,
    backgroundColor: theme.colors.white,
    boxShadow: theme.shadows[2],
  },
}));

const PageTemplate: React.FunctionComponent = () => {

  const classes = useStyles();
  const location = useLocation();
  const userData = useAppSelector((state: any) => state.user);
  const userAccess = userData.userAccess;

  const queryParams = getQueryParams(location);
  const embed = queryParams.get("embed");
  const isEmbed = embed === "true" ? true : false;

  return !userAccess.calendar ? (
    <Navigate to="/403" state={{ from: location }}/>
  ) : (
    <div className={classes.calendarPage}>
      {
        !userAccess.subscription ? (
          <>
            {
              isEmbed ? (
                <div className={classes.calendarMenu}>
                  <CalendarMenu/>
                </div>
              ) : null
            }
            <Calendar/>
            <SubscriptionBanner service="calendar" isInCenter={true}/>
          </>
        ) : (
          <>
            {
              isEmbed ? (
                <div className={classes.calendarMenu}>
                  <CalendarMenu/>
                </div>
              ) : null
            }
            <Calendar/>
          </>
        )
      }
    </div>
  );
};

export default PageTemplate;